import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";


// const API_URL = 'https://winx.upmspboard.com/wp-json/wp/v2/posts';

// Fetch post by ID
// async function fetchPostById(id) {
//     console.log(id)
//     const res = await fetch(`${API_URL}/${id}`);
//     const post = await res.json();
//     return post;
// }



const Blogposdt = () => {
    const [blogData, setBlogData] = useState([]);
    const [mainImage, setMainImage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [post,setpost] = useState(null)
    const { Blogid } = useParams();

    // useEffect( () => {
    //     const fetchdata = async() => {
    //         const Psots =  await fetchPostById(Blogid)
    //         setpost(Psots)

    //     }
    //     fetchdata()
    // }, [Blogid]);

 
    return (
        <>
            <div>
                {/* Display the main image */}
               

                {post &&
                    <div>
                        {/* Display the blog content */}
                        <h1 className="Miantitle"  >{post.title.rendered}+ {"tite"}</h1>
                        <div className="CONTENT" dangerouslySetInnerHTML={{ __html: post.content.rendered }} />

                    </div>}
            </div>
        </>
    );
};

export default Blogposdt;
