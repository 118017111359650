import axios from "axios";

const API = axios.create({
  baseURL: "http://localhost:5000/api/game", // Match backend route
});

// Add user authentication token to every request if needed
API.interceptors.request.use((req) => {
  const token = localStorage.getItem("token");
  if (token) req.headers.Authorization = `Bearer ${token}`;
  return req;
});

export const startGame = (userId,bet) => API.post("/play", {userId, bet });
export const fetchUserData = () => API.get("/user/data");
