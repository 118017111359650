import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Quizbox.css";

async function filterPostsByRegion(posts, region) {
    const regionCategoryMap = {
        Gamimng: "category-gamingcrpto",
        india: "category-india",
        usa: "category-usa",
        africa: "category-africa",
    };

    const categoryToFilter = regionCategoryMap[region] || "category-usa";

    const filteredPosts = posts.filter(
        (post) =>
            Array.isArray(post.class_list) &&
            post.class_list.includes(categoryToFilter)
    );

    return filteredPosts;
}

const Quizbox = ({ data }) => {
    const [allPosts, setAllPosts] = useState([]);
    // const API_URL = "https://winx.upmspboard.com/wp-json/wp/v2/posts?per_page=50";

    // async function fetchAllPosts() {
    //     const res = await fetch(API_URL);
    //     const posts = await res.json();
    //     return posts;
    // }

    // useEffect(() => {
    //     let isMounted = true; // Track component mounting
    //     const fetchData = async () => {
    //         try {
    //             const allPosts = await fetchAllPosts();
    //             console.log(allPosts)

    //             const posts = await filterPostsByRegion(allPosts, "Gamimng");
    //             console.log(posts)
    //             if (isMounted) setAllPosts(posts);
    //         } catch (error) {
    //             console.error("Error fetching posts:", error);
    //         }
    //     };
    //     fetchData();

       
    // }, []);

    return (
        <>
            {/* <h2>Crypto Gaming Update</h2> */}

            {/* <div style={{ display: "flex", overflow: "auto", width: "100%" }}>
                {allPosts &&
                    allPosts.map((x) => (
                        <Link
                            key={x.id}
                            style={{ textDecoration: "none" }}
                            to={`/Blogposdt/${x.id}`}
                        >
                            <div style={{ width: "276px" }} className="css-sdqkp0">
                                <img
                                    style={{ maxWidth: "265px", height: "307px" }}
                                    className="rounded-t-lg"
                                    src={`${x.jetpack_featured_media_url}`}
                                    alt=""
                                />

                                <div style={{ padding: "5px" }}>
                                    <h2 className="mb-2 jAUurx dark:text-black">
                                        {x.title.rendered}
                                    </h2>
                                    <div className="Timeimogi">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="size-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                            />
                                        </svg>
                                        <span style={{ fontSize: "15px", marginLeft: "10px" }}>
                                            {x.date}
                                        </span>
                                    </div>
                                    <span>
                                        Read more
                                        <svg
                                            style={{ marginLeft: "10px" }}
                                            className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 14 10"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M1 5h12m0 0L9 1m4 4L9 9"
                                            />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </Link>
                    ))}
            </div> */}

            <h2>
                Create Your <span className="Mark">Quiz</span>
            </h2>
            <h2>Best Friend & Couple Quiz</h2>
            <div className="css-vr61ey">
                {data.map((x, index) => (
                    <Link
                        key={index}
                        style={{ textDecoration: "none" }}
                        to={`/quizpage/${
                            x.name === "Best friend Quiz"
                                ? "friend"
                                : x.name === "couple Quiz"
                                ? "couple"
                                : x.name === "Free fire Quiz"
                                ? "freefire"
                                : x.name
                        }`}
                    >
                        <div className="css-sdqkp0">
                            {x.img}
                            <h3 style={{ color: "black", fontSize: "17px" }}>{x.name}</h3>
                        </div>
                    </Link>
                ))}
            </div>
        </>
    );
};

export default Quizbox;
