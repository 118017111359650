import React, { useState, useEffect } from "react";
import Home from "./Pages/Home2";
import { useParams } from "react-router-dom";
import Game from "../src/Component/Game"
import Quizpage from "./Pages/quizpage";
import Live from "./Pages/Livequiz"
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Blogposdt from "./Pages/Blogpost"
import CompetitionsPage from "./Pages/Compatationpage"
// import AdsComponent from "../src/Component/Addsence /Addsence";

import NormalQuiz from "../src/Pages/NoramalQuiz";

import AdminPanel from "../src/Pages/Adminpannel";
import Anstpage from "../src/Pages/ansqestionpage";
import TermsOfService from "../src/Pages/Teremuse";
import Aboutuse from "../src/Pages/Aboutus";
import Privasy from "../src/Pages/Privesy";
import Cotectus from "../src/Pages/Contecus";
import Gamepage from "../src/Pages/Gamepage";
import ResponsiveDrawer from "../src/Component/Navbar/NAvbar";
function App() {

  const [rows, setRows] = useState("");
  const [headCells, setHeadCells] = useState("");

 
  // console.log(id);
  return (
    <>
      <div
        style={{
          marginTop: "80px",
          padding: "0px 12px 0px 12px",
          marginBottom: "100px",
        }}
      >
              <ResponsiveDrawer props={window} />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:id?" element={<Home />} />
          <Route path="/Privasy" element={<Privasy />} />
          <Route path="/Game" element={<Game />} />

          <Route path="/Legal" element={<Privasy />} />
          <Route path="/About Us" element={<Aboutuse />} />
          <Route path="/Tearms Of Use" element={<TermsOfService />} />
          <Route path="/Contect Us" element={<Cotectus />} />
          <Route path="/Gamepage/:id" element={<Gamepage />} />
          <Route path="/NormalQuiz/:quizname" element={<NormalQuiz />} />
          <Route path="/quizpage/:quizname" element={<Quizpage />} />
          <Route path="/quizpage/Anstpage/:quiId" element={<Anstpage />} />
          <Route path="/AdminNirakar" element={<AdminPanel />} />
          <Route path="/Live/:quiId" element={<Live/>} />
          <Route path="/CompetitionsPage" element={<CompetitionsPage/>} />
          <Route path="/Blogposdt/:Blogid" element={<Blogposdt/>} />
        </Routes>
      </div>
    </>
  );
}

export default App;
