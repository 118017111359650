import React, { useState } from "react";
import { startGame } from "../utils/api";

const Game = () => {
  const [bet, setBet] = useState(10); // Default bet amount
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  const userId = "fasdfa234323"

  const handleStartGame = async () => {
    try {
      setError(null); // Reset error state
      console.log(userId)
      const response = await startGame(userId,parseInt(bet));
      console.log(response.data.result)
      // setResult(response.data.result); // Win or Lose
    } catch (error) {
      console.error("Error starting game:", error);
      setError("Failed to start the game. Please try again.");
    }
  };

  return (
    <div className="game-container">
      <h1>Mine Game</h1>
      <input
        type="number"
        value={bet}
        onChange={(e) => setBet(e.target.value)}
        min="10"
        placeholder="Enter your bet"
      />
      <button onClick={handleStartGame}>Start Game</button>
      {result && (
        <div className={`result ${result === "win" ? "win" : "lose"}`}>
          You {result}! {result === "win" ? "🎉" : "😢"}
        </div>
      )}
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default Game;
